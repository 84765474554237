import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";

import { resetPasswordConfirm } from "network/ApiAxios";
import Logo from "components/Logo";
import AuthFooter from "components/Footers/AuthFooter";
import AuthAnimation from "./AuthAnimation";

const ResetPasswordConfirm = () => {
  const { uid, token } = useParams();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!uid || !token) {
      setError("Invalid or missing UID/token.");
    }
  }, [uid, token]);

  const handlePasswordResetConfirm = async (e) => {
    e.preventDefault();

    if (!password.trim()) {
      setError("Password is required.");
      return;
    }
    if (!password2.trim()) {
      setError("Confirm Password is required.");
      return;
    }
    if (password !== password2) {
      setError("Passwords do not match.");
      return;
    }

    const data = await resetPasswordConfirm(uid, token, password, password2);
    setError(data.message);
  };

  return (
    <>
      <Col xl="4" lg="5" md="7" className="d-flex flex-column mx-lg-0 mx-auto">
        <Logo />
        <Card className="card-plain">
          <CardHeader className="pb-0 text-start">
            <h4 className="font-weight-bolder">Reset Account Password</h4>
            <p className="mb-0">Enter new password</p>
          </CardHeader>
          <CardBody>
            <Form role="form" onSubmit={handlePasswordResetConfirm}>
              <FormGroup className="mb-3">
                <Input
                  className="form-control-lg"
                  name="password"
                  placeholder="New Password"
                  type="password"
                  autoComplete="new-email"
                  onChange={e => setPassword(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Input
                  className="form-control-lg"
                  name="password2"
                  placeholder="Re-enter New Password"
                  type="password"
                  autoComplete="new-email"
                  onChange={e => setPassword2(e.target.value)}
                  required
                />
              </FormGroup>

              {error && (
                <div className="text-muted font-italic">
                  <small>
                    <span className="text-danger fw-700">{error}</span>
                  </small>
                </div>
              )}
              <div className="text-center">
                <Button type="submit" className="btn-lg btn-dark btn-lg w-100 mt-4 mb-0" color="primary">
                  Reset Password
                </Button>
              </div>
            </Form>
          </CardBody>
          <CardFooter className="text-center pt-0 px-lg-2 px-1">
            <p className="mb-2 text-sm mx-auto">
              Already have an account?
              <a href="/auth/login" className="text-primary text-gradient font-weight-bold"> Login here</a>
            </p>
            <p className="mb-2 text-sm mx-auto">
              Don't have an account?
              <a href="/auth/register" className="text-primary text-gradient font-weight-bold"> Sign up</a>
            </p>
          </CardFooter>
        </Card>
        <AuthFooter />
      </Col>


      <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-left flex-column">
        <div className="position-relative bg-gradient-dark h-100 m-3 border-radius-lg d-flex flex-column overflow-hidden" >
          <span className="mask bg-gradient-dark opacity-6" ></span>
          <AuthAnimation />
        </div >
      </div >
    </>
  );
};

export default ResetPasswordConfirm;

import {
  Container,
  Row,
  Col,
} from "reactstrap";

const AdminNavbar = (props) => {
  return (
    <>
      <Container className="position-sticky z-index-sticky top-0">
        <Row>
          <Col className="" xs="12">
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminNavbar;

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard.scss";
import 'assets/css/dhi.css';

import AuthLayout from "layouts/Auth.js";
import ChatLayout from "layouts/Chat.js";
import MainLayout from "layouts/Main.js";

import { ChatProvider } from "contexts/ChatContext";
import SettingsProvider from "contexts/SettingsContext";
import { SidebarProvider } from "contexts/SidebarContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <SettingsProvider>
      <SidebarProvider>
        <ChatProvider>
          <Routes>
            <Route path="/auth/*" element={<AuthLayout />} />
            <Route path="/c/*" element={<ChatLayout />} />
            <Route path="/*" element={<MainLayout />} />
            <Route path="*" element={<Navigate to="/auth/login" replace />} />
          </Routes>
        </ChatProvider>
      </SidebarProvider>
    </SettingsProvider>
  </BrowserRouter>
);

import React, { useState, useEffect } from 'react';

const TextWithAnimation = ({ heading, text, onComplete }) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        // Reset the displayed text when the heading changes
        setDisplayedText('');

        let timeoutIds = [];

        // Animate the text after the heading fades in
        const timeoutId = setTimeout(() => {
            for (let i = 0; i < text.length; i++) {
                let typingTimeoutId = setTimeout(() => {
                    setDisplayedText(text.substr(0, i + 1));

                    // When the last character is set, call onComplete
                    if (i === text.length - 1) {
                        onComplete();
                    }
                }, i * 100); // Speed of typing each character
                timeoutIds.push(typingTimeoutId);
            }
        }, 2000); // Time to wait after the heading is shown and before the text starts animating
        timeoutIds.push(timeoutId);

        // Cleanup function to clear timeouts when the component unmounts or updates
        return () => {
            timeoutIds.forEach(clearTimeout);
        };
    }, [heading, text, onComplete]);

    return (
        <div>
            <h2 className="text-white font-weight-bolder position-relative fade-in">{heading}</h2>
            <h4 className="typing-container">
                <span className="typing text-white text-wrap">{displayedText}</span>
            </h4>
        </div>
    );
};

const AnimatedText = ({ texts }) => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    const handleComplete = () => {
        // Move to the next text after a delay
        const timer = setTimeout(() => {
            setCurrentTextIndex((index) => (index + 1) % texts.length);
        }, 3000); // Time to wait before showing the next heading and starting the process over

        // Cleanup function to clear timer when the component unmounts or updates
        return () => clearTimeout(timer);
    };

    const currentText = texts[currentTextIndex];

    return (
        <TextWithAnimation
            heading={currentText.heading}
            text={currentText.text}
            onComplete={handleComplete}
        />
    );
};

export default AnimatedText;

import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Col,
} from "reactstrap";

import Logo from "components/Logo";
import AuthFooter from "components/Footers/AuthFooter";
import AuthAnimation from "./AuthAnimation";
import { activateAccount } from "network/ApiAxios";

const Activate = () => {
    const { uid, token } = useParams();
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (uid && token) {
            activateAccount(uid, token).then(data => {
                setMessage(data.message);
            }).catch(error => {
                setMessage("Failed to activate account. Please try again.");
            });
        }
    }, [uid, token]);

    return (
        <>
            <Col xl="4" lg="5" md="7" className="d-flex flex-column mx-lg-0 mx-auto">
                <Logo />
                <Card className="card-plain">
                    <CardHeader className="pb-0 text-center">
                        <h4 className="font-weight-bolder">Account Activation</h4>
                    </CardHeader>
                    <CardBody className="text-center">
                        {message && <div className="text-danger">{message}</div>}
                    </CardBody>
                    <CardFooter className="text-center pt-0 px-lg-2 px-1">
                        <p className="mb-2 text-sm mx-auto">
                            Already have an account?
                            <a href="/auth/login" className="text-primary text-gradient font-weight-bold"> Login here</a>
                        </p>
                        <p className="mb-2 text-sm mx-auto">
                            Don't have an account?
                            <a href="/auth/register" className="text-primary text-gradient font-weight-bold"> Sign up</a>
                        </p>
                    </CardFooter>
                </Card>
                <AuthFooter />
            </Col>


            <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-left flex-column">
                <div className="position-relative bg-gradient-dark h-100 m-3 border-radius-lg d-flex flex-column overflow-hidden" >
                    <span className="mask bg-gradient-dark opacity-6" ></span>
                    <AuthAnimation />
                </div >
            </div >
        </>
    );
};

export default Activate;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from "reactstrap";

import { Auth } from '../Auth';
import { conversation } from "network/ApiAxios";
import { useChatContext } from 'contexts/ChatContext';
import ChatMessageDisplay from 'components/Chat/ChatMessageDisplay';
import { imageAssets } from 'components/imageAssets';
import FooterInnerNav from 'components/Footers/FooterInnerNav';
import config from 'core/config';

const Chat = (props) => {
    const navigate = useNavigate();
    const { conversationsUpdated, refreshConversations } = useChatContext();
    const { id } = props;
    const token = localStorage.getItem('token');
    const [messages, setMessages] = useState([]);
    const [userPrompt, setUserPrompt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [regenerateMessageId, setRegenerateMessageId] = useState(null);
    const scrollRef = useRef(null);
    const formRef = useRef(null);
    const textareaRef = useRef(null);
    const requestRef = useRef(null);
    const animationSpeed = 1;

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
            if (textarea.scrollHeight > 200) {
                textarea.style.overflowY = 'auto'; // Enable scrollbar when max height is reached
            } else {
                textarea.style.overflowY = 'hidden'; // Hide scrollbar until max height is reached
            }
        }
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [userPrompt]);

    useEffect(() => {
        if (id) {
            // setCurrentConversationId(id);
        }
    }, [id]);

    // Create a new message
    const createUserMessage = (queryText) => {
        return {
            id: `user-${new Date().getTime()}`,
            message: {
                id: `user-${new Date().getTime()}`,
                author: {
                    role: "user"
                },
                create_time: new Date().toISOString(),
                update_time: new Date().toISOString(),
                content: {
                    content_type: "text",
                    parts: [
                        queryText
                    ]
                },
            },
            parent: null,
            children: []
        };
    };

    useEffect(() => {
        if (id) {
            const getConversation = async () => {
                try {
                    const data = await conversation(token, id);
                    setMessages(data.mapping ? Object.values(data.mapping) : []);
                } catch (error) {
                    console.error('Failed to fetch conversation:', error);
                    setMessages([]);
                }

                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            };
            getConversation();
        }
    }, [id, token, regenerateMessageId, conversationsUpdated]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [messages]);

    const [animatingMessage, setAnimatingMessage] = useState('');
    const [animatingIndex, setAnimatingIndex] = useState(0);

    useEffect(() => {
        // If there's a message to animate and the index is within the message length
        if (animatingMessage && animatingIndex < animatingMessage.length) {
            requestRef.current = setTimeout(() => {
                setMessages(prevMessages => {
                    const newMessages = [...prevMessages];
                    const lastMessageIndex = newMessages.length - 1;

                    // Ensure there's at least one message
                    if (lastMessageIndex < 0) return newMessages;

                    const currentText = animatingMessage.substring(0, animatingIndex + 1);

                    // Update only the last message's content
                    newMessages[lastMessageIndex] = {
                        ...newMessages[lastMessageIndex],
                        message: {
                            ...newMessages[lastMessageIndex].message,
                            content: {
                                ...newMessages[lastMessageIndex].message.content,
                                parts: [currentText]
                            }
                        }
                    };

                    return newMessages;
                });

                setAnimatingIndex(prevIndex => prevIndex + 1);
            }, animationSpeed);
        } else if (animatingMessage) {
            // Animation complete
            setAnimatingMessage('');
            setAnimatingIndex(0);
        }

        // Cleanup on unmount or when animatingMessage changes
        return () => clearTimeout(requestRef.current);
    }, [animatingMessage, animatingIndex, animationSpeed]);

    // Function to handle the message typing process
    const typeWriter = (assistantMessageId, newText) => {
        setAnimatingMessage(newText);
        setAnimatingIndex(0);
    };

    // Handle message submission
    const handleSubmit = async (event, query = null, messageId = null, parentId = null) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (query == null) {
            query = userPrompt.trim();
        }

        if (!query) return;
        setIsLoading(true);
        setUserPrompt('');

        const messageInput = formRef.current.elements.messageInput;
        if (!messageInput) {
            console.error('Message input not found');
            setIsLoading(false);
            return;
        }

        const messageValue = query ? query : messageInput.value;
        const userMessage = createUserMessage(userPrompt);
        if (messageId) {
            setRegenerateMessageId(messageId);
        } else {
            setMessages(prevMessages => [...prevMessages, userMessage]);
        }

        try {
            // Use fetch for streaming response
            const response = await fetch(config.API_BASE_URL + 'v1/conversation/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify({
                    message: messageValue,
                    parent_id: parentId || null,
                    session_id: id || null,
                    message_id: messageId || null
                })
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            let done = false;
            let assistantMessage = {
                id: `${messageId}`,
                message: {
                    id: `${messageId}`,
                    author: {
                        role: "assistant"
                    },
                    create_time: new Date().toISOString(),
                    update_time: new Date().toISOString(),
                    content: {
                        content_type: "text",
                        parts: [""]
                    },
                },
                parent: null,
                children: []
            };

            if (!messageId) {
                // Append the new assistant message
                setMessages(prevMessages => [...prevMessages, assistantMessage]);
            }
            let fullMessage = '';

            while (!done) {
                const { value, done: streamDone } = await reader.read();
                done = streamDone;

                if (value) {
                    let chunk = decoder.decode(value, { stream: true });

                    // Split by lines to handle multiple data events
                    const lines = chunk.split('\n').filter(line => line.startsWith('data:'));
                    for (let line of lines) {
                        const jsonString = line.replace(/^data:\s*/, '');

                        try {
                            const parsedData = JSON.parse(jsonString);

                            // Ignore keep-alive comments
                            if (parsedData.status === 'DONE') {
                                done = true;
                                navigate(`/c/${parsedData.conversation_id}`);
                                break;
                            }

                            const parts = parsedData.message.content.parts;
                            assistantMessage.id = parsedData.message.id;
                            assistantMessage.parent = parsedData.parent;
                            assistantMessage.message.id = parsedData.message.id;

                            if (parts && parts.length > 0) {
                                const newPart = parts[0];
                                fullMessage = newPart;

                                // Trigger the typing animation by passing the full message incrementally
                                typeWriter(assistantMessage.id, fullMessage);
                            }

                        } catch (error) {
                            console.error('Failed to parse JSON:', error);
                        }
                    }
                }
            }

        } catch (error) {
            console.error('Failed to send message:', error);
        } finally {
            setIsLoading(false);
            setRegenerateMessageId(null);
            refreshConversations();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    return (
        <>
            <div
                className="pt-md-0 mb-3 h-100 d-flex w-100 px-3 px-lg-1 bg-white"
                style={{ maxHeight: '85vh', overflowY: 'auto', overflowX: 'hidden' }}
                ref={scrollRef}
            >
                <Row className="mt-2 h-100 flex-grow-1 d-flex">
                    <Col className="h-100 flex-grow-1 d-flex" xl="12">
                        <div className="mb-0 mb-xl-0 h-100 flex-grow-1 d-flex flex-column">
                            <div className="pt-2 flex-grow-1">
                                <div className="p-0 h-100 position-relative">
                                    {messages && messages.length > 0 ? (
                                        <ChatMessageDisplay
                                            mapping={messages}
                                            sessionId={id}
                                            regenerateResponse={handleSubmit}
                                            regMsdId={regenerateMessageId}
                                            isLoading={isLoading}
                                            totalMessages={messages.length > 0 ? messages.length : 0}
                                        />
                                    ) : (
                                        <div className="flex h-100 flex-col text-center align-content-center text-token-text-primary">
                                            <div className="relative">
                                                <div className="mb-3 h-12 w-12">
                                                    <div className="gizmo-shadow-stroke relative flex h-100 text-center align-content-center rounded-full bg-token-main-surface-primary text-token-text-primary">
                                                        <div className="mx-auto">
                                                            <img src={imageAssets.bluelogoicon} className="w-4" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-5 text-2xl font-medium text-black">
                                                How can I help you today?
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="justify-content-center sticky-bottom px-4 bg-white">
                <form ref={formRef} className="align-items-center w-75 mx-auto" onSubmit={handleSubmit}>
                    <div className="d-flex border border-1 p-2" style={{ borderRadius: '26px', backgroundColor: '#f4f4f4' }}>
                        <textarea
                            name="messageInput"
                            className="border-0 bg-transparent form-control p-0 rounded-0 shadow-none mx-3 resize-none align-self-center"
                            placeholder="Message Bevan"
                            aria-label="Message Bevan input"
                            aria-describedby="button-addon2"
                            value={userPrompt}
                            onChange={(e) => {
                                setUserPrompt(e.target.value);
                                adjustTextareaHeight();
                            }}
                            onKeyDown={handleKeyDown}
                            rows="1"
                            ref={textareaRef}
                            style={{ overflowY: 'auto', maxHeight: '25dvh', }}
                        />
                        <button
                            className="btn bg-gradient-dark mb-0 ml-2 p-0 text-white rounded-circle align-self-end"
                            type="submit"
                            disabled={!userPrompt.trim()}
                            style={{ width: "2rem", height: "2rem" }}
                        >
                            {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm text-light"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <i className="ni ni-send"></i>
                            )}
                        </button>
                    </div>
                </form>
                <p className="text-center text-xs mt-2 mb-0">
                    Bevan can make mistakes. Consider checking important information.
                </p>
                <FooterInnerNav />
            </div>
        </>
    );
};

export default Auth(Chat);

import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Form, FormGroup, Label, Input } from 'reactstrap';
import { FaSpinner } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { userSettings } from 'network/ApiAxios';

const SettingsModal = ({ isOpen, toggle }) => {
    const token = localStorage.getItem('token');
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [settings, setSettings] = useState({
        first_name: '',
        last_name: '',
        email: '',
        meta_data: []
    });

    const fetchSettings = useCallback(async () => {
        try {
            const response = await userSettings(token);
            if (response) {
                setSettings(response);
            }
        } catch (error) {
            console.error('Failed to fetch settings:', error);
            setSettings([]);
        }
    }, [token]);


    useEffect(() => {
        if (isOpen) {
            fetchSettings();
        }
    }, [isOpen, fetchSettings]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings(prevSettings => ({
            ...prevSettings,
            [name]: value
        }));
    };

    const saveUserSettings = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const { first_name, last_name } = settings;

        if (!first_name || !last_name) {
            setError("First name and last name are required.");
            setIsLoading(false);
            return;
        }

        try {
            const data = {
                first_name,
                last_name,
            };
            const response = await userSettings(token, 'post', data)
            if (response.success) {
                setError("");
                toggle();
            } else {
                setError(response.message || "Failed to save settings.");
            }
        } catch (error) {
            setError("Failed to save settings. Please try again.");
        }
        setIsLoading(false);
        return;
    };

    const getMetaValue = (key) => {
        const meta = settings.meta_data.find(meta => meta.meta_key === key);
        if (meta && key.includes("date")) {
            const date = new Date(meta.meta_value);
            return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
        }
        return meta ? meta.meta_value : '-';
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} tabIndex="-1" role="dialog" aria-labelledby="settingsModal" aria-hidden="true">
            <Form>
                <div className="modal-header">
                    <h5 className="modal-title">Settings</h5>
                    <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <FormGroup className='p-3'>
                    <h5 for="email">Bevan Subscription</h5>
                    <p className='m-0'>Your plan expires on <strong className='text-danger'>{getMetaValue('subscription_end_date')}</strong>.</p>
                </FormGroup>
                <hr className='border m-0' />
                <div className="modal-body">
                    <FormGroup>
                        <Label for="name">First Name</Label>
                        <Input
                            type="text" name="first_name" id="first_name" placeholder="Enter your first name"
                            value={settings.first_name}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="name">Last Name</Label>
                        <Input
                            type="text" name="last_name" id="last_name"
                            placeholder="Enter your last name"
                            value={settings.last_name}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <div className='badge badge-secondary w-100 p-3 text-start text-lowercase'>{settings.email}</div>
                    </FormGroup>

                    {error && (
                        <div className="text-muted font-italic">
                            <small>
                                <span className="text-danger fw-700">{error}</span>
                            </small>
                        </div>
                    )}
                </div>

                <div className="modal-footer">
                    <button type="submit" onClick={saveUserSettings} className="btn bg-gradient-dark">
                        {isLoading ? <FaSpinner className="icon-spin" /> : 'Save'}
                    </button>
                    <button type="button" className="btn btn-link  ml-auto" onClick={toggle}>Cancel</button>
                </div>
            </Form>
        </Modal >
    );
};

SettingsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};

export default SettingsModal;

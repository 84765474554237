import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";
import { FaSpinner } from 'react-icons/fa';

import Logo from "components/Logo";
import AuthFooter from "components/Footers/AuthFooter";
import { login } from "network/ApiAxios";
import AuthAnimation from "./AuthAnimation";



const Login = () => {
  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [is_subscriber, setIsSubscriber] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/auth/login');
    } else {
      navigate('/c/');
    }
  }, [navigate]);

  const tryLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!email.trim() || !password.trim()) {
      setError("Email & password are required.");
    }

    const data = await login(email, password, is_subscriber);
    if (data.success) {
      setError("");
      localStorage.setItem("token", data.token);
      localStorage.setItem("user_firstname", data.user.first_name);
      localStorage.setItem("user_lastname", data.user.last_name);
      navigate('/c/');
    } else {
      setPassword("");
      setIsSubscriber("");
      setError(data.msg);
    }
    setIsLoading(false);
    return;
  };
  return (
    <>
      <Col xl="4" lg="5" md="7" className="d-flex flex-column mx-lg-0 mx-auto">
        <Logo />
        <Card className="card-plain">
          <CardHeader className="pb-0 text-start">
            <h4 className="font-weight-bolder text-29334A">Sign In</h4>
            <p className="mb-0 d-none">Enter your email and password to sign in</p>
          </CardHeader>
          <CardBody>
            <Form role="form">
              <FormGroup className="mb-3">
                <Input
                  className="form-control-lg"
                  name="email"
                  placeholder="Email"
                  type="email"
                  autoComplete="email"
                  onChange={e => setUsername(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  className="form-control-lg"
                  name="password"
                  placeholder="Password"
                  type="password"
                  autoComplete="password"
                  onChange={e => setPassword(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <div className="form-check">
                  <Input
                    id="is_subscriber"
                    name="is_subscriber"
                    type="checkbox"
                    checked={is_subscriber}
                    onChange={e => setIsSubscriber(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="is_subscriber">Select If Intelligence Subscriber.</label>
                </div>
              </FormGroup>

              {error && (
                <div className="text-muted font-italic">
                  <small>
                    <span className="text-danger fw-700">{error}</span>
                  </small>
                </div>
              )}
              <div className="text-center">
                <Button type="submit" onClick={tryLogin} className="btn-lg btn-primary btn-lg w-100 mt-4 mb-0" color="custom">
                  {isLoading ? <FaSpinner className="icon-spin" /> : 'Sign in'}
                </Button>
              </div>
            </Form>
          </CardBody>
          <CardFooter className="text-center pt-0 px-lg-2 px-1">
            <p className="mb-2 text-sm mx-auto">
              Don't have an account?
              <a href="/auth/register" className="text-custom text-gradient font-weight-bold"> Sign up</a>
            </p>
            <p className="mb-2 text-sm mx-auto">
              Forgot password?
              <a href="/auth/reset-password" className="text-custom text-gradient font-weight-bold"> Reset here!</a>
            </p>
          </CardFooter>
        </Card>

        <AuthFooter />
      </Col>

      <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-left flex-column">
        <div className="position-relative bg-gradient-dark h-100 m-3 border-radius-lg d-flex flex-column overflow-hidden" >
          <AuthAnimation />
        </div >
      </div >
    </>
  );
};

export default Login;

const TermsConditions = () => {
    return (
        <>
            <div className="container">
                <h4 className="text-3572EF">Terms & Conditions</h4>
                <p className="text-dark"></p>

            </div >
        </>
    );
};

export default TermsConditions;
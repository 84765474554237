// CategorizedList.js
import React from 'react';
import moment from 'moment';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useLocation } from 'react-router-dom';

const ShowByDate = ({ items }) => {
    const location = useLocation();
    const fixedOrderCategories = ['Today', 'Yesterday', 'Previous 7 Days', 'Previous 30 Days'];

    const getCategorizedItems = () => {
        const now = moment();
        let categories = {
            'Today': [],
            'Yesterday': [],
            'Previous 7 Days': [],
            'Previous 30 Days': [],
        };

        items.forEach(item => {
            const itemMoment = moment(item.update_time);
            const diffDays = now.diff(itemMoment, 'days');
            const monthYearFormat = itemMoment.format('MMMM YYYY');

            if (diffDays < 1 && now.isSame(itemMoment, 'day')) {
                categories['Today'].push(item);
            } else if (diffDays < 2 && now.clone().subtract(1, 'day').isSame(itemMoment, 'day')) {
                categories['Yesterday'].push(item);
            } else if (diffDays < 7) {
                categories['Previous 7 Days'].push(item);
            } else if (diffDays < 30) {
                categories['Previous 30 Days'].push(item);
            } else if (itemMoment.isSame(now, 'year')) {
                if (!categories[monthYearFormat]) {
                    categories[monthYearFormat] = [];
                }
                categories[monthYearFormat].push(item);
            } else {
                const yearFormat = itemMoment.format('YYYY');
                if (!categories[yearFormat]) {
                    categories[yearFormat] = [];
                }
                categories[yearFormat].push(item);
            }
        });

        categories = [...fixedOrderCategories, ...Object.keys(categories).filter(category => !fixedOrderCategories.includes(category)).sort((a, b) => {
            if (moment(a, 'MMMM YYYY').isValid() && moment(b, 'MMMM YYYY').isValid()) {
                return moment(b, 'MMMM YYYY').toDate() - moment(a, 'MMMM YYYY').toDate();
            }
            if (moment(a, 'YYYY').isValid() && moment(b, 'YYYY').isValid()) {
                return moment(b, 'YYYY').toDate() - moment(a, 'YYYY').toDate();
            }
            return 0;
        })].reduce((obj, key) => {
            if (categories[key] && categories[key].length > 0) {
                obj[key] = categories[key];
            }
            return obj;
        }, {});

        return categories;
    };

    const renderCategoryItems = (categoryItems) => {
        const sortedItems = categoryItems.sort((a, b) => {
            return moment(b.update_time).toDate() - moment(a.update_time).toDate();
        });
        return sortedItems.map(item => (
            <NavItem key={item.id} className={`m-0 ${location.pathname === `/c/${item.id}` ? 'active' : ''}`}>
                <NavLink href={`/c/${item.id}`} className="text-black m-0 p-2 text-sm fw-normal">
                    <div className='position-relative overflow-hidden text-nowrap w-100'>
                        {item.title.replace(/<\/?[^>]+(>|$)/g, "")}
                        <div className={`position-absolute top-0 bottom-0 end-0 w-50 ${location.pathname === `/c/${item.id}` ? 'sidebar-gradient-secondary' : 'sidebar-gradient-primary'}`}>&nbsp;</div>
                    </div>
                </NavLink>
            </NavItem >
        ))
    };

    const categorizedItems = getCategorizedItems();

    return (
        <div>
            {Object.entries(categorizedItems).map(([category, categoryItems]) => {
                return (
                    <React.Fragment key={category}>
                        {categoryItems.length > 0 && (
                            <div className='mt-4 text-capitalize'>
                                <h6 className="text-secondary text-tertiary text-xs px-2 mt-2">{category}</h6>
                                <Nav className="mb-md-3" navbar>
                                    {renderCategoryItems(categoryItems)}
                                </Nav>
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default ShowByDate;
